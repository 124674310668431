// AOS
import AOS from 'aos';
window.AOS = AOS;
import 'aos/dist/aos.css';

// Image Compare
import ImageCompare from 'image-compare-viewer';
window.ImageCompare = ImageCompare;
import 'image-compare-viewer/dist/image-compare-viewer.min.css';

// Typed.js
import Typed from 'typed.js';
window.Typed = Typed;

// CountUp.js
import { CountUp } from 'countup.js';
window.CountUp = CountUp;